.landing-page{
  .img-style{
    height: 155px;
    width: 200px;
  }
  
  .landing-image{
   width: 100%;
    height: 100vh;
    // width: 70vw;
    // height: 99.6vh;
  }
}